// import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import headerImg from "../assets/img/header-img.svg";

// import headerImg2 from "../assets/img/header-img1.svg";
import img1 from "../assets/img/Datalake-Azure.png"
// import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import rp from "../assets/img/resource-provisioning.png";
import adf from "../assets/img/adf-working.png";
import databricks from "../assets/img/databricks.png";
import synapse from "../assets/img/synapse.png";
import powerbi from "../assets/img/powerbi.png";

export const Lakehouse = () => {
    return(
        <section className="projectdetails" id="home">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={12} xl={12}>
              

                <div> 
                  <span className="intro">Project Introduction:</span> 
                  <h1> Designing and Implementing an ETL Process with a Data Lakehouse and Data Warehouse on Azure</h1>
                    <p>This project aimed to build a scalable and efficient ETL (Extract, Transform, Load) pipeline, using Azure services to create a unified data lakehouse, then migrate curated data to a data warehouse for reporting and analytics. By leveraging Azure Data Factory (ADF) for data orchestration, Azure Databricks for data transformations, Azure Data Lake Storage Gen2 (ADLS Gen2) for storage, and Azure Synapse Analytics for data warehousing, this project achieved a robust architecture suited for large-scale data processing and analytics. 
                      </p>
                </div>
             
            </Col>
            
          
            <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
              <h4> Project Objectives:</h4>

                  <p>
                  1.	Design a data lakehouse in ADLS Gen2 to handle structured and semi-structured data from multiple sources.</p>
<p>2.	Implement a scalable ETL process using ADF to manage data ingestion, cleansing, and transformation.</p>
<p>3.	Build a data warehouse in Azure Synapse Analytics to support business intelligence and reporting.</p>


                  
              </div>
           
          </Col>
          <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
              <h4>Project Steps</h4>
<h4>1. Requirements Gathering and Data Source Identification </h4>
<p>We began by gathering the project requirements and identifying data sources to ensure all relevant information was accounted for. Key data sources included:</p>
                  <p>
                  <b>•	Relational Databases: </b>SQL Server, which held transactional data.<br></br>
                  <b>•	APIs:</b> REST APIs providing semi-structured data.<br></br>
                  <b>•	On-premises Files:</b> Flat files containing historical data.<br></br>
                  <b>•	Cloud Storage:</b> External data sources in various formats (CSV, JSON).
</p>
<p>The requirements also involved defining the schema for the data lakehouse and data warehouse, setting data retention policies, and ensuring the solution complied with regulatory requirements.</p>


                  
              </div>
           
          </Col>

          <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>2. Resource Provisioning in Azure </h4>
<p>To establish the necessary infrastructure, we set up the following Azure resources:</p>
  <p>
    <b>1.	Azure Data Lake Storage Gen2 (ADLS Gen2):</b><br></br>
    •	ADLS Gen2 was provisioned to handle the scalable storage of large datasets.<br></br>
•	We created a hierarchy of containers within ADLS Gen2 to segregate different stages of data:<br></br>
•	Raw Data: Stores unprocessed data as it is ingested from source systems.<br></br>
•	Curated Data: Contains data that has been cleaned, structured, and transformed.<br></br>
•	Enriched Data: Holds aggregated and derived data ready for the data warehouse.

</p>                  
<p>
<b>2.	Azure Data Factory (ADF):</b><br></br>
• ADF was configured to orchestrate the end-to-end data pipeline. We enabled managed identity in ADF for secure access to other resources.<br></br>
•	Linked Services were created to connect ADF with SQL databases, REST APIs, ADLS Gen2, and Azure Synapse.<br></br>
</p>     

<p>
<b>3.	Azure Databricks (Apache Spark):</b><br></br>
• We set up an Azure Databricks workspace to serve as the primary engine for data transformations.

<br></br>
•	Access was granted to ADLS Gen2 for seamless data reading and writing using service principal authentication.<br></br>
</p>     
<p>
 <b>4.	Azure Synapse Analytics:</b><br></br>
  • Azure Synapse was provisioned to host the data warehouse, designed to support high-volume, complex analytics.

<br></br>
•	We configured dedicated SQL pools in Synapse to allow for fast querying and optimized data warehousing performance.<br></br>
</p>     
    </div>
           
          </Col>

<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={rp} alt="Resources Image"/>
<h6> Resources Allocation Architecture Diagram</h6>
                  </div>
              
            </Col>

            <Col xs={12} md={6} xl={5} className="coldiff">
              
           
              <div> 

               <img src={adf} alt="ADF Image"/>
               <h6> Data Factory Internal Working Architecture Diagram</h6>

             </div>
         
       </Col>
          <Col xs={12} md={6} xl={7} className="coldiff">
              
                
              <div> 
               
             
<h4>3. Data Ingestion and Extraction with Azure Data Factory </h4>
<p>After resource provisioning, we designed and implemented data pipelines for data ingestion in ADF. 
  We focused on creating separate ingestion flows for batch and near-real-time data requirements.</p>
  <p>
    <b>1.	Linked Services Setup:</b><br></br>
    •	We created Linked Services in ADF to securely connect with the SQL Server databases, REST APIs, and ADLS Gen2.
</p>                  
<p>
<b>2.	Batch Data Ingestion:</b><br></br>
• We utilized ADF’s Copy Data activity to pull data from source databases into the Raw Data container in ADLS Gen2.<br></br>
•	For each data source, we created custom ingestion pipelines with triggers set to automate data pulls on a scheduled basis.<br></br>
</p>     

<p>
<b>3.	Near-Real-Time Data Processing:</b><br></br>
•	For streaming data, we configured Event Hubs to capture and temporarily store data.

<br></br>
•	ADF pipelines were then set up to retrieve and store this data into the raw storage layer of ADLS Gen2.<br></br>
</p>     
<p>
 <b>4.	Data Quality Checks:</b><br></br>
 • We implemented data quality checks within ADF to verify data integrity. These checks validated schema consistency, checked for missing values, and performed basic transformations (like type casting).

<br></br>
• Any anomalies or errors were logged and flagged for further review.<br></br>
</p>     
    </div>
           
          </Col>


          <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>4. Data Transformation with Azure Databricks </h4>
<p>With data loaded into ADLS Gen2, we proceeded to cleanse, standardize, 
  and transform the raw data using Azure Databricks.</p>
  <p>
    <b>1.	Transformation Logic Development:</b><br></br>
    •	We developed Databricks notebooks in PySpark, applying business logic to clean and enrich the data.

<br></br>
• Key transformations included removing duplicates, handling null values, converting data types, and applying business-specific rules for data enrichment.<br></br>
</p>                  
<p>
<b>2.	Data Transformation Execution:</b><br></br>
• Jobs were created in Databricks to execute the transformation notebooks, which were orchestrated through ADF to maintain a consistent workflow.<br></br>
•	We leveraged ADF to schedule and trigger these transformations after data ingestion steps completed.<br></br>
</p>     

<p>
<b>3.	Aggregation and Partitioning:</b><br></br>
• We applied aggregations to summarize data as needed for reporting purposes.
<br></br>
• To optimize storage and retrieval efficiency, data was partitioned based on key fields (e.g., date, region) and stored in the Curated Data container in ADLS Gen2.<br></br>
</p>     
     
    </div>
           
          </Col>

<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={databricks} alt="Databricks Image"/>
                    <h6> Databricks Components Working Architecture Diagram</h6>

                  </div>
              
            </Col>

            <Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={synapse} alt="Synapse Image"/>
                    <h6> Synapse Analytics Internal Working Architecture Diagram</h6>

                  </div>
              
            </Col>
            <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>5. Data Loading from Data Lakehouse to Data Warehouse</h4>
<p>With curated data in place, we shifted focus to loading this data into Azure Synapse 
  Analytics for advanced analytics and reporting.</p>
  <p>
    <b>1.	Synapse Integration with ADLS Gen2:</b><br></br>
    •	We used PolyBase to connect Synapse directly to ADLS Gen2, allowing 
    for fast data ingestion from the curated data layer.
<br></br>
• By connecting Synapse directly to ADLS Gen2, we could stage data temporarily before final loading into structured tables.<br></br>
</p>                  
<p>
<b>2.	Data Transformation in Synapse:</b><br></br>
• Using SQL scripts and dataflows in Synapse, we organized the data into fact and dimension tables, creating a star schema to support efficient querying.<br></br>
•	Transformations involved mapping data to Synapse tables with measures and dimensions required for business reports.<br></br>
</p>     

<p>
<b>3.	Optimizing Data Load Performance:</b><br></br>
• To handle large data loads, we used Synapse’s COPY INTO command, which allowed bulk data transfer from ADLS Gen2.
<br></br>
• Indexes and partitioning were applied to optimize query performance for frequently accessed tables.</p>         
    </div>
           
          </Col>



            
            <Col xs={12} md={5} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>6. Pipeline Orchestration and Monitoring</h4>
<p>With data pipelines in place, we focused on orchestrating and monitoring the entire 
  ETL process to ensure reliability and scalability.</p>
  <p>
    <b>1.	ADF Pipeline Orchestration:</b><br></br>
    •	We used ADF’s triggers and dependencies to set up an end-to-end workflow, linking each data processing stage seamlessly from 
    ingestion to transformation and finally to Synapse.
<br></br>
• Conditional logic and retry mechanisms were applied to handle potential errors, with alerts and notifications configured for pipeline failures.<br></br>
</p>                  
<p>
<b>2.	Monitoring and Logging:</b><br></br>
• Monitoring features in ADF were leveraged to track pipeline execution status, with error and warning alerts set to notify the team.<br></br>
•	Databricks logs provided insights into job execution, enabling us to troubleshoot any transformation issues.
</p>     

 </div>
           
          </Col>
{/* 
<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={rp} alt="Resources Image"/>

                  </div>
              
            </Col> */}

            <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>7. Business Intelligence Integration and Reporting</h4>
<p>Once the data was available in Synapse, we integrated it with Power BI to enable real-time reporting.</p>
  <p>
    <b>1.	Power BI Connection to Synapse:</b><br></br>
    •	We configured Power BI to connect to Synapse using DirectQuery mode, allowing users to view up-to-date reports without frequent refreshes.
<br></br>
• Datasets in Power BI were linked directly to Synapse tables, enabling self-service reporting for business users.<br></br>
</p>                  
<p>
<b>2.	Building Dashboards:</b><br></br>
• Interactive dashboards and reports were created in Power BI to provide insights on key business metrics.<br></br>
•	We implemented role-based access control, ensuring that each department had access only to the relevant data.
</p>     

 </div>
           
          </Col>

<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={powerbi} alt="Power BI Image"/>
                    <h6> Power BI Connectivity Architecture Diagram</h6>

                  </div>
              
            </Col>

            <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>8. Maintenance, Optimization, and Future Enhancements</h4>
<p>To ensure the system remained efficient and secure, we implemented the following maintenance and optimization practices:</p>
  <p>
    <b>1.	Performance Tuning:</b><br></br>
    •	We regularly reviewed pipeline and query performance in ADF and Synapse.

<br></br>
• Query plans and Spark job performance were optimized to reduce processing times and lower costs.<br></br>
</p>                  
<p>
<b>2.	Data Purging and Retention:</b><br></br>
• Purge policies were set up on ADLS Gen2 to delete obsolete data, optimizing storage costs.<br></br>
•	In Synapse, data archival processes were established for historical data beyond retention requirements.<br></br>
</p>     

<p>
<b>3.	Security and Compliance:</b><br></br>
• Data encryption at rest and in transit was enforced to meet regulatory compliance.
<br></br>
•Auditing was enabled in both Synapse and ADF to track access and changes.</p>     
     
    </div>
           
          </Col>

{/* <Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={rp} alt="Resources Image"/>

                  </div>
              
            </Col> */}
          </Row>
        </Container>
      </section>



)
}