// import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { NavBar } from "../Components/NavBar";
import { NavBar} from "../Components/NavBar";
import { Banner } from "../Components/Banner";
import { Skills } from "../Components/Skills";
import { Projects } from "../Components/Projects";
import { Footer } from "../Components/Footer";

function HomePage() {
  return (
    <div className='App'>
    <NavBar/>
    <Banner/>
    <Skills/>
    <Projects/>
    <Footer/>
    </div>
  );
}

export default HomePage; 
