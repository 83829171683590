// import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { NavBar } from "../Components/NavBar";
import { NavBar} from "../Components/NavBar";
// import { Banner } from "../Components/Banner";
// import { Skills } from "../Components/Skills";
// import { Projects } from "../Components/Projects";
import { Footer } from "../Components/Footer";
import {Lakehouse} from "../Components/Lakehouse";

function Project1() {
  return (
    <div>
    <NavBar/>
    <Lakehouse/>
    <Footer/>
    </div>
  );
}

export default Project1; 