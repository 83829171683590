import meter1 from "../assets/img/da.png";
import meter2 from "../assets/img/ai.png";
import meter3 from "../assets/img/cc.png";
import meter4 from "../assets/img/ml.png";
import meter5 from "../assets/img/DE.png";
import meter6 from "../assets/img/bi.png";

// import m1 from "../assets/img/bd2.png";
// import mmeter2 from "../assets/img/ai2.png";
// import mmeter3 from "../assets/img/da2.png";
// import mmeter4 from "../assets/img/ci.png";
// import mmeter5 from "../assets/img/de2.png";
// import mmeter6 from "../assets/img/ml2.png";

// import meter2 from "../assets/img/meter2.svg";
// import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import arrow1 from "../assets/img/arrow1.svg";
// import arrow2 from "../assets/img/arrow2.svg";
// import colorSharp from "../assets/img/color-sharp.png"



export const Skills = () => {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
  
    return (
      <section className="skill" id="skills">
          {/* <div className="container1" >
              <div className="row skill-shape">
              <h2>Our Services and Skills</h2>
                          <p className="skill-line">We offer a comprehensive range of data solutions designed to help businesses manage, process, 
                            and analyze their data efficiently, ensuring they can leverage it for strategic growth and innovation. </p>

                  <div className="col-6 display1">
                      <div className="skill-bx wow zoomIn">
                          <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider hidden">
                          <div responsive ={responsive} infinite ={true}> 
                               <div className="item">
                                  <img src={meter1} alt="skill1" />
                                  <h5>Data Analysis:</h5>
                                  <p> Delivering comprehensive data insights for informed decision-making.</p>
                                 
                              </div>
                              <div className="item">
                                  <img src={meter4} alt="skill2" />
                                  <h5>Data Science & Machine Learning:</h5>
                                    <p>Developing predictive models to solve business challenges.</p>

                              </div>
                              <div className="item">
                                  <img src={meter3} alt="skill2" />
                                  <h5>Cloud Integration:</h5>
                                    <p>Building scalable, secure environments on Azure and AWS.</p>

                              </div>
                          </Carousel>
                          </div>
                                                    </Carousel>

                      </div>
                  </div> 
                  <div className="col-6 display1">
                      <div className="skill-bx wow zoomIn">
                          <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                          <div responsive ={responsive} infinite ={true}> 

                              <div className="item">
                                  <img src={meter6} alt="skill1" />
                                  <h5>Business Intelligence (BI):</h5>
                                 <p> Transforming raw data into meaningful insights for strategic decisions.</p>
                              </div>
                              <div className="item">
                                  <img src={meter2} alt="skill2" />
                                  <h5>Big Data Solutions:</h5>
                                  <p>Processing large datasets to drive actionable insights. </p>
                              </div>
                              <div className="item">
                                  <img src={meter5} alt="skill2" />
                                  <h5>Data Engineering:</h5>
                                  <p>Designing robust pipelines and infrastructure for seamless data management.</p>
                              </div>
                              </div>
                          </Carousel>
                      </div>
                  </div>

                  </div>
</div> */}

{/* Start of Carousal for mobile displays */}

<div className="container2">
                  <div className="row skill-shape2">
              <h2 className="carousal-header">Our Services and Skills</h2>
                          <p className="skill-line2">We offer a comprehensive range of data solutions designed to help businesses manage, process, 
                            and analyze their data efficiently, ensuring they can leverage it for strategic growth and innovation. </p>

                            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider" >
                          
                               <div className="items2">
                                  <img src={meter1} alt="skill12" />
                                  <h5>Data Analysis:</h5>
                                  <p> Delivering comprehensive data insights for informed decision-making.</p>
                                 
                              </div>
                              <div className="items2">
                                  <img src={meter4} alt="skill22" />
                                  <h5>Data Science & Machine Learning:</h5>
                                    <p>Developing predictive models to solve business challenges.</p>

                              </div>
                              <div className="items2">
                                  <img src={meter3} alt="skill22" />
                                  <h5>Cloud Integration:</h5>
                                    <p>Building scalable, secure environments on Azure and AWS.</p>

                          </div>
                          <div className="items2">
                                  <img src={meter6} alt="skill1" />
                                  <h5>Business Intelligence (BI):</h5>
                                 <p> Transforming raw data into meaningful insights for strategic decisions.</p>
                              </div>
                              <div className="items2">
                                  <img src={meter2} alt="skill2" />
                                  <h5>Big Data Solutions:</h5>
                                  <p>Processing large datasets to drive actionable insights. </p>
                              </div>
                              <div className="items2">
                                  <img src={meter5} alt="skill2" />
                                  <h5>Data Engineering:</h5>
                                  <p>Designing robust pipelines and infrastructure for seamless data management.</p>
                              </div>
                         </Carousel>

                  </div>
</div>
      </section>




      
    )
  }
  