import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, URL }) => {
  return (
    <Col size={12} sm={8} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt="Project Card" />
        <div className="proj-txtx">
          <h4> {title}</h4>
          <span>{description}</span>
          <h6>{URL}</h6>
        </div>
      </div>
    </Col>
  )
}
