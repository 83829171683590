import { Container, Row, Col } from "react-bootstrap";

// import headerImg2 from "../assets/img/header-img1.svg";
// import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Details = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}> 
                <span className="tagline">About Us </span>
                  <p>At Super Advance Business Technologies Solutions (SATS), we are at the forefront of the digital revolution, delivering cutting-edge data analytics and data science solutions that empower businesses to make smarter, data-driven decisions. 
                    Our expertise spans across a range of technologies, including Azure, AWS, and other industry-leading platforms, enabling us to provide a comprehensive suite of services tailored to meet the unique needs of our clients.
                    </p>
                    <br></br>
                  <p> <b>Who We Are</b> <br></br>
                  We are a team of seasoned data professionals, including data scientists, engineers, and analysts, with deep expertise in leveraging advanced analytics and cloud technologies. Our mission is to help businesses unlock the full potential of their data, transforming it into actionable insights that drive growth, innovation, and competitive advantage.</p>
                  <br></br>
                  <p><b> What We Do</b><br></br>
At the core of our services is a deep commitment to delivering value through data. We specialize in:<br></br>

<br></br><b>Data Analytics:</b> From data extraction to visualization, we offer end-to-end data analytics solutions that enable businesses to uncover trends, optimize operations, and make data-driven decisions in real-time.<br></br>
<br></br><b>Data Science & Machine Learning:</b> We build and deploy predictive models, using the latest in AI and machine learning techniques to solve complex business problems and predict future outcomes with accuracy.<br></br>
<br></br><b>Cloud Integration & Data Engineering:</b> Our team is proficient in integrating and managing data environments on leading cloud platforms such as Microsoft Azure and Amazon Web Services (AWS). We help businesses seamlessly move to the cloud, ensuring scalable, secure, and cost-effective data infrastructure.<br></br>
<br></br><b>Big Data Solutions:</b> We harness the power of big data tools and technologies, allowing organizations to process vast datasets efficiently, derive insights at scale, and enhance decision-making across all levels.<br></br>
<br></br><b>Business Intelligence (BI):</b> By designing and implementing powerful BI systems, we enable companies to transform raw data into meaningful and actionable insights that drive strategic decision-making.</p><br></br>

                  <p><b> Our Approach</b><br></br>
                  We take a client-centric approach, partnering closely with businesses to understand their unique challenges and goals. Our solutions are tailored to meet the specific needs of each client, whether they are just starting their data journey or looking to 
                  optimize and scale their existing data infrastructure. With a focus on innovation, agility, and best practices, we ensure that our clients remain at the cutting edge of data-driven transformation.</p><br></br>
                  <p><b> Why Choose Us?</b><br></br>
                  
Expertise in Leading Technologies: Our team is certified and experienced across the major cloud and data platforms, including Azure, AWS, Google Cloud, and more, ensuring best-in-class solutions.
Proven Track Record: We have successfully delivered data analytics and data science projects for clients across various industries, from finance and healthcare to retail and manufacturing.
Tailored Solutions: We understand that no two businesses are alike, which is why we customize our services to align with your specific business goals and data needs.
Commitment to Excellence: Our passion for data drives us to continuously evolve and stay ahead of industry trends, ensuring we deliver the most innovative and impactful solutions.
At Super Advance Business Technologies Solutions (SATS), we believe that data is the new currency of business. With our expertise, we help you harness it effectively to stay ahead of the competition. Let us be your partner in your data-driven journey to success.                  </p>

              </div>}
             </TrackVisibility> 
          </Col>

        </Row>
      </Container>
    </section>
  )
}
