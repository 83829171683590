// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { NavBar } from "./Components/NavBar";
// import { Banner } from "./Components/Banner";
// import { Skills } from "./Components/Skills";
// import { Projects } from "./Components/Projects";
// import { Footer } from "./Components/Footer";
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Project11 from './pages/Project1';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';




function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route exact path='/' element ={<HomePage/>}/>
        <Route exact path='/About' element ={<About/>}/>
        <Route exact path='/Project1' element={<Project11/>}/>
      </Routes>
      </Router>
     {/* <HomePage/> */}
     {/* <About/> */}
         </div>
  );
}

export default App;
